import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Grid } from "@mui/material";
import MKBox from "components/MKBox";

import closeup from "assets/images/Registration-of-a-Private-Limited-Company.jpg";
import office from "assets/images/millenial.jpg";
import office2 from "assets/images/modern-office-space-with-desktops-with-modern-computers-created-with-generative-ai-technology.jpg";
import document from "assets/images/businessman-examining-papers-table.jpg";

export default function ActionAreaCard() {
  return (
    <Grid container>
      <Grid md={6} padding={2}>
        <Card>
          <CardActionArea sx={{ display: "grid", justifyContent: "center" }}>
            <CardMedia
              component="img"
              height="120px"
              image={closeup}
              sx={{ justifySelf: "center" }}
              title="closeup"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                จดทะเบียนห้างหุ้นส่วน ตรายาง+โลโก้ ฟรี! ราคา 4,500 บาท
              </Typography>
              <MKBox component="ul" mx={{ fontSize: "16px" }} p={2}>
                <MKBox component="li" mb={1}>
                  จดทะเบียนภาษีมูลค่าเพิ่ม
                </MKBox>
                <MKBox component="li" mb={1}>
                  ลงทะเบียนคู่ค้ากับภาครัฐ (e-GP)
                </MKBox>
                <MKBox component="li" mb={1}>
                  ลงทะเบียนระบบ SME เพื่อการจัดซื้อจัดจ้างภาครัฐ (SME-GP)
                </MKBox>
              </MKBox>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid md={6} padding={2}>
        <Card>
          <CardActionArea sx={{ display: "grid", justifyContent: "center" }}>
            <CardMedia
              component="img"
              height="120px"
              image={office}
              sx={{ justifySelf: "center" }}
              title="office"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                จดทะเบียนบริษัท ตรายาง+โลโก้ ฟรี! ราคา 8,500 บาท
              </Typography>
              <MKBox component="ul" mx={{ fontSize: "16px" }} p={2}>
                <MKBox component="li" mb={1}>
                  จดทะเบียนภาษีมูลค่าเพิ่ม
                </MKBox>
                <MKBox component="li" mb={1}>
                  ลงทะเบียนคู่ค้ากับภาครัฐ (e-GP)
                </MKBox>
                <MKBox component="li" mb={1}>
                  ลงทะเบียนระบบ SME เพื่อการจัดซื้อจัดจ้างภาครัฐ (SME-GP)
                </MKBox>
              </MKBox>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid md={6} padding={2}>
        <Card sx={{ height: { md: "100%" } }}>
          <CardActionArea sx={{ height: { md: "100%" }, display: "grid" }}>
            <CardMedia
              component="img"
              height="120px"
              image={office2}
              sx={{ justifySelf: "center" }}
              title="office2"
            />
            <CardContent sx={{ height: { md: "200px" } }}>
              <Typography gutterBottom variant="h5" component="div">
                จดทะเบียนเลิกกิจการ ห้างหุ้นส่วนและบริษัท รวมปิดงบเลิกกิจการ
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid md={6} padding={2}>
        <Card>
          <CardActionArea sx={{ display: "grid", justifyContent: "center" }}>
            <CardMedia
              component="img"
              height="120px"
              image={document}
              sx={{ justifySelf: "center" }}
              title="document"
            />
            <CardContent>
              <MKBox component="ul" mx={{ fontSize: "16px" }} p={2}>
                <MKBox component="li" mb={1}>
                  จดทะเบียนเปลี่ยนแปลงที่อยู่
                </MKBox>
                <MKBox component="li" mb={1}>
                  เปลี่ยนแปลงชื่อกรรมการ เปลี่ยนแปลงอำนาจกรรมการ
                </MKBox>
                <MKBox component="li" mb={1}>
                  เปลี่ยนเพิ่ม/ลด/แก้ไข วัตถุประสงค์หนังสือรับรอง
                </MKBox>
                <MKBox component="li" mb={1}>
                  จดทะเบียนเพิ่มทุนบริษัท
                </MKBox>
              </MKBox>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
}
