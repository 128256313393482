import React from "react";
import { Paper, Typography, List, ListItem, Icon } from "@mui/material";
import PropTypes from "prop-types";

const listItemStyle = {
  padding: "8px",
};

const backgroundImages = {
  primary: "linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)",
  secondary: "linear-gradient(to top, #accbee 0%, #e7f0fd 100%)",
};

const getRandomBackgroundImage = (color) => {
  if (color == null) {
    return backgroundImages["primary"];
  }
  return backgroundImages[color];
};

const PricingCard = ({ data }) => {
  const backgroundImage = getRandomBackgroundImage(data.bg);
  const itemList = data.details.map((item, index) => {
    return (
      <ListItem sx={listItemStyle} key={index}>
        <Icon>done</Icon>
        <Typography variant="body2">{item}</Typography>
      </ListItem>
    );
  });

  return (
    <Paper
      elevation={1}
      sx={{
        padding: "40px 10px",
        justifyContent: "center",
        textAlign: "center",
        maxWidth: "400px",
        borderRadius: "0.75rem",
        boxShadow:
          "0rem 0.625rem 0.9375rem -0.1875rem rgba(0, 0, 0, 0.1), 0rem 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.05)",
        backgroundImage,
        color: "#000",
        display: "flex",
        height: { md: "100%" },
      }}
    >
      <div style={{ width: "100%" }}>
        <Typography
          variant="h5"
          mb={3}
          sx={{
            backgroundColor: "#fff",
            padding: "10px",
            borderRadius: "5px",
            textDecorationLine: "underline",
          }}
        >
          {data.title}
        </Typography>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: "200px",
              height: "200px",
              backgroundColor: "#fff",
              borderRadius: "50%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              marginBottom: "2rem",
              border: "5px solid #46b5ff",
            }}
          >
            <Typography variant="h1">
              <small
                style={{
                  position: "absolute",
                  fontSize: "14px",
                  marginTop: "5px",
                  marginLeft: "-14px",
                }}
              >
                <Icon>currency_bitcoin_icon</Icon>
              </small>
              {data.price}
              <small style={{ fontSize: "14px" }}> {data.ending}</small>
            </Typography>
          </div>
        </div>
        <div>
          <List>{itemList}</List>
        </div>
        <Typography
          component="p"
          mb={3}
          sx={{ padding: "10px", color: "red", textDecorationLine: "underline" }}
        >
          {data.hint}
        </Typography>
      </div>
    </Paper>
  );
};

PricingCard.propTypes = {
  data: PropTypes.object,
};

export default PricingCard;
