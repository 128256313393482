import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, size, price) {
  return { name, size, price };
}

const rows = [
  createData("SS", "1-10 บิล", 800),
  createData("S", "1-20 บิล", "1,400"),
  createData("M", "1-40 บิล", "2,500"),
  createData("L", "1-60 บิล", "3,500"),
  createData("XL", "1-100 บิล", "5,000"),
];

export default function CustomizedTables() {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ display: "table-header-group" }}>
          <TableRow>
            <StyledTableCell align="center" width="10%">
              แพ็คเกจ
            </StyledTableCell>
            <StyledTableCell align="center">ปริมาณ</StyledTableCell>
            <StyledTableCell align="center">ค่าบริการ</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell align="center" component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="center">{row.size}</StyledTableCell>
              <StyledTableCell align="center">{row.price}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
