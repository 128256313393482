/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Presentation page sections
// import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import Profile from "pages/LandingPages/Author/sections/Profile";
import PromotionBlocks from "./sections/PromotionBlocks";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/calculating-finance-numbers-cool.jpg";

function Presentation() {
  return (
    <div>
      <DefaultNavbar
        routes={routes}
        // action={{
        //   type: "external",
        //   route: "https://www.creative-tim.com/product/material-kit-react",
        //   label: "free download",
        //   color: "info",
        // }}
        sticky
      />
      <MKBox
        id="top"
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} md={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h2"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("xl")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              <MKTypography variant="span" sx={{ color: "#1effdc" }}>
                HUG
              </MKTypography>{" "}
              <MKTypography variant="span" sx={{ color: "#acc4ff" }}>
                Accounting and Service
              </MKTypography>
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
              fontSize="18px"
            >
              ผู้ให้บริการรับทำบัญชี ยื่นภาษี ยื่นงบการเงิน รับปิดงบการเงินย้อนหลัง รับทำบัญชีรายปี
              วางแผนภาษี จดทะเบียนบริษัท <br />
              จดเลิกกิจการ บริการด้าน การวางแผน กำหนดกลยุทธธุรกิจ วางแผนกลยุทธ์ การจัดการ การตลาด
              การเงิน วางแผน
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {/* <Counters /> */}
        <Information />
        <PromotionBlocks />
      </Card>
      <Card sx={{ p: 2, mx: { xs: 2, lg: 3 }, mt: 12 }}>
        <Container>
          <Profile />
        </Container>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </div>
  );
}

export default Presentation;
