/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/business-office-sence.jpg";
import bgBack from "assets/images/office-with-documents-money-accounts.jpg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={<>ใช้บริการของเราในการจัดการบัญชี</>}
                description="ประสบการณ์ด้านตรวจสอบบัญชี จากหนึ่งในสี่บริษัทตรวจสอบบัญชีรายใหญ่ที่สุดของโลก (Big 4) ระยะเวลามากกว่า 4 ปี "
              />
              <RotatingCardBack
                image={bgBack}
                title="ข้อมูลเพิ่มเติม"
                // description="You will save a lot of time going from prototyping to full-functional code because all elements are implemented."
                action={{
                  type: "anchor",
                  route: "profile",
                  label: "ติอต่อเรา",
                }}
                description=""
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="businessIcon"
                  title="จดทะเบียนบริษัท"
                  description="บริการจดทะเบียนบริษัท ห้างหุ้นส่วน จัดทำตรายาง จดทะเบียนภาษีมูลค่าเพิ่ม ลงทะเบียนคู่ค้ากับรัฐ(e-GP) ลงทะเบียนระบบ SME(SME-GP)"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="monetization_on_icon"
                  title="ยื่นภาษี"
                  description="ยื่นภาษีเงินได้หัก ณ ที่จ่าย ภงด.1 ภงด.3 และ ภงด.53 หรือ ภพ.30 หรือรายการที่ไม่ประจำอื่น โดยเรามีบริการจดทะเบียนยื่นภาษีออนไลน์"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="account_balance_icon"
                  title="ยื่นประกันสังคม"
                  description="จัดทำและยื่นแบบประกันสังคม"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="forum_icon"
                  title="ให้คำปรึกษา"
                  description="ให้คำปรึกษาในการจัดทำบัญชี การจดทะเบียน และการยื่นภาษี"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
