/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
import PricingCard from "examples/Cards/PricingCards";
import CustomizedTables from "examples/table";
import ActionAreaCard from "examples/Cards/ServiceCards";

function PromotionBlocks() {
  const data = [
    {
      title: "บริการด้านบัญชีและภาษีรายเดือน",
      price: "900",
      ending: "บาท/เดือน",
      details: [
        "จัดทำและยื่นเงินได้หัก ณ ที่จ่าย ภงด.1,3,53",
        "จัดทำและยื่นภาษีมูลค่าเพิ่ม (ภพ.30,36) ภาษีธุรกิจเฉพาะ (ภธ.40)",
        "จัดทำและยื่นแบบประกันสังคม",
        "ให้คำปรึกษาตลอด",
      ],
      bg: "secondary",
    },
    {
      title: "บริการด้านบัญชีและภาษีรายปี",
      price: "2,500",
      details: [
        "จัดทำและยื่นภาษีเงินได้นิติบุคคล แบบ ภงด.50,51",
        "จัดทำและยื่นภาษีหัก ณ ที่จ่ายประจำปี ภงด.1ก.",
        "จัดทำสมุดรายวัน, สมุดบัญชีแยกประเภทตามที่กฎหมายกำหนด",
        "จัดทำงบทดลองแลงบการเงิน พร้อมทั้ง จัดทำรายละเอียดประกอบงบการเงิน",
        "ให้คำปรึกษาตลอด",
      ],
    },
    {
      title: "บริการด้านการตรวจสอบบัญชี",
      price: "5,000",
      details: [
        "ตรวจสอบบัญชีตามมาตรฐานการสอบบัญชีที่รับรองทั่วไป โดยทีมงานผู้สอบบัญชี รับอนุญาตที่มีประสบการณ์ (CPA)",
      ],
      bg: "secondary",
    },
  ];
  const result = data.map((item, index) => (
    <Grid xs={12} md={4} key={index} px={2} mb={2}>
      <PricingCard data={item} />
    </Grid>
  ));

  return (
    <MKBox component="section" my={6} py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          {/* <MKBadge
            variant="contained"
            color="info"
            badgeContent="Promotion Section"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            โปรโมชันวันนี้
          </MKTypography> */}
          {/* <MKTypography variant="body1" color="text">
            We have created multiple options for you to put together and customise into pixel
            perfect pages.
          </MKTypography> */}
        </Grid>
      </Container>
      <Container>
        <Grid container mb={4}>
          {result}
        </Grid>
        <Grid container mb={5}>
          <Grid md={4} p={3} sx={{ alignItems: "center", display: "flex", textAlign: "center" }}>
            <MKTypography component="h5" fontWeight="bold">
              อัตราค่าบริการรายเดือนของเรา <br />
              (รวมค่าทำ ยื่นภาษีและประกันสังคม)
            </MKTypography>
          </Grid>
          <Grid md={8}>
            <CustomizedTables />
          </Grid>
        </Grid>
        <Grid container>
          <Grid md={4} p={3} sx={{ alignItems: "center", display: "flex", textAlign: "center" }}>
            <MKTypography component="h5" fontWeight="bold">
              บริการจดทะเบียนบริษัท
            </MKTypography>
          </Grid>
          <Grid md={8}>
            <ActionAreaCard />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default PromotionBlocks;
