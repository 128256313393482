// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
// import logoCT from "assets/images/logo-ct-dark.png";
import hugLogo02 from "assets/images/hug-logo-02.jpg";
import lineQR from "assets/images/lineQR.jpg";

const date = new Date().getFullYear();
export default {
  brand: {
    // name: "Material Kit 2",
    image: hugLogo02,
    route: "/",
    name: "HUG Accounting and Service",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      // link: "#",
    },
    {
      icon: <TwitterIcon />,
      // link: "#",
    },
    {
      icon: <GitHubIcon />,
      // link: "#",
    },
    {
      icon: <YouTubeIcon />,
      // link: "#",
    },
  ],
  menus1: [
    {
      name: "Menu",
      items: [
        { name: "หน้าแรก", href: "top" },
        { name: "โปรไฟล์", href: "profile" },
        { name: "ติดต่อ", href: "contact" },
      ],
    },
  ],
  place: {
    name: "ที่ตั้งสำนักงาน",
    items: [
      {
        name: "321 หมู่ 11 ต.บัวแดง อ.ปทุมรัตต์ จ.ร้อยเอ็ด 45190",
      },
    ],
  },
  contact: {
    name: "ติดต่อเรา",
    items: [
      {
        name: "Email: hug.accservice@gmail.com",
        href: "mailto:hug.accservice@gmail.com",
      },
      { name: "Tel: 095-6361464", href: "tel:095-6361464" },
      { name: "Line: wilasinee.tae" },
    ],
    lineQR: lineQR,
  },
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}
      <MKTypography
        component="a"
        href="https://www.creative-tim.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        {" "}
        Wilasinee
      </MKTypography>
      .
    </MKTypography>
  ),
};
